body {
  //padding-right: 0 !important;
  //margin-right: 0 !important;
}

.font-16px {
  font-size: 16px;
}

.font-28px {
  font-size: 28px;
}

.font-20px {
  font-size: 20px;
}

.no-margin-left {
  margin-left: 0;
}

.p-font-15px p {
  font-size: 15px;
}

div.nopadding {
  padding-left: 0px;
  padding-right: 0px;
}

.photo {
  background: white;
  padding: 15px 15px 15px 15px;
  margin: 5px 5px 5px 5px;
  height: auto;
  border: #909090 1px solid;
  background: #fff;
  color: #333;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#909090, direction=120, strength=4);
  -moz-box-shadow: 2px 2px 10px #909090;
  -webkit-box-shadow: 2px 2px 10px #909090;
  box-shadow: 2px 2px 10px #909090;
}

.padding-20px{
  padding-left:20px;
  padding-right:20px;
}

.margin-horizon{
  margin:20px 0;
}

.margin-vertical{
  margin:0 20px;
}

.no-border{
  border:none;
  background-color: transparent;
}

.no-display{
  display:none;
}

textarea {
  max-height: 94px; /* optional, but recommended */
  min-height: 31px;
  overflow-x: hidden; /* for Firefox (issue #5) */
}

td.vertical-middle{
  vertical-align: middle;
}